












import Vue from "vue";
import MainMenu from "../components/MainMenu.vue";
export default Vue.extend({
  name: "Main",
  components: {
    MainMenu
  },
  metaInfo: () => {
    return {
      meta: [
        {
          name: "twitter:card",
          content: "Manuel Schmidt - Softwareentwickler für Software die passt"
        },
        { name: "twitter:site", content: "@Krosan89" },
        {
          name: "twitter:title",
          content: "Manuel Schmidt - Softwareenwicklung"
        },
        {
          property: "og:url",
          content: "https://www.manuel-schmidt.at"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "Manuel Schmidt - Softwareenwicklung"
        },
        {
          property: "og:title",
          content: "Manuel Schmidt - Softwareentwickler für Software die passt"
        }
      ],
      htmlAttrs: {
        lang: "de",
      }
    };
  }
});
