








































import Vue from "vue";
export default Vue.extend({
  name: "OwnSoftware",
  metaInfo: () => {
    return {
      title: "Manuel Schmidt",
      titleTemplate: "%s - Demo Software",
      meta: [
        {
          name: "description",
          content: "Software die ich zur freien Verwendung zur Verfügung stelle"
        },
        {
          name: "keywords",
          content: "software entwicklung, angular, modern subnet, software development, ux, innsbruck, mobile, apps, web"
        }
      ]
    };
  }
});
