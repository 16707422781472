


















import Vue from "vue";
import Logo from "./Logo.vue";

export default Vue.extend({
  name: "MainMenu",
  components: {
    Logo
  }
});
