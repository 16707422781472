import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from './sides/Main.vue'
import SoftwareDevelopment from './sides/SoftwareDevelopment.vue'
import CustomerSoftware from './sides/CustomerSoftware.vue'
import OwnSoftware from './sides/OwnSoftware.vue'
import About from './sides/About.vue'
import Legal from './sides/Legal.vue'
import Meta from 'vue-meta';
import VueGtag from "vue-gtag";

Vue.use(VueRouter);
Vue.use(Meta);

Vue.config.productionTip = false;

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: About
    },
    {
      path: '/software-entwicklung',
      component: SoftwareDevelopment
    },
    {
      path: '/kunden-software',
      component: CustomerSoftware
    },
    {
      path: '/eigene-software',
      component: OwnSoftware
    },
    {
      path: '/impressum',
      component: Legal
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
});
// deactivate Google Analytics
/*
Vue.use(VueGtag, {
config: {
  id: 'UA-116157247-1',
  params: {
    storage: 'none',
    anonymize_ip: true,
  }
}},
  router
);
*/
new Vue({
  router,
  render: h => h(Main),
}).$mount('#app');
