


























import Vue from "vue";
import DigitalTransformation from "../components/DigitalTransformation.vue";
import DigitalTransformationMobile from "../components/DigitalTransformationMobile.vue";
import { MobileDetect } from "../mixin/MobileDetect";

export default Vue.extend({
  name: "About",
  components: {
    DigitalTransformation,
    DigitalTransformationMobile
  },
  data: () => {
    return {
      window: {
        width: 0,
        height: 0
      }
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    }
  },
  mixins: [MobileDetect],
  metaInfo: () => {
    return {
      title: "Manuel Schmidt",
      titleTemplate: "%s - Softwareentwickler!",
      meta: [
        {
          name: "description",
          content:
            "Innovative Softwareentwicklung für ihre Kunden Software Projekte."
        },
        {
          name: "keywords",
          content:
            "software entwicklung, angular, nodejs, software development, ux, innsbruck, tirol, mobile, apps, web, pwa, nestjs, typescript"
        }
      ]
    };
  }
});
