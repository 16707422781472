






















































import Vue from "vue";
export default Vue.extend({
  name: "CustomerSoftware",
  metaInfo: () => {
    return {
      title: "Manuel Schmidt",
      titleTemplate: "%s - Customer Referenzen",
      meta: [
        {
          name: "description",
          content: "Der Kunde und Benutzer stehen immer im Mittelpunkt und es bestehende Kundensoftwareprojekte für Camyno GmbH, Interfacewerk GmbH und anderen namhaften Unternehmen bestätigen das."
        },
        {
          name: "keywords",
          content:
            "software entwicklung, kunden projekte, angular, nodejs, software development, ux, innsbruck, mobile, apps, web"
        }
      ]
    };
  }
});
