

































import Vue from "vue";
export default Vue.extend({
  name: "Legal",
    metaInfo: () => {
    return {
      title: "Manuel Schmidt",
      titleTemplate: "%s - Impressum",
      meta: [
        {
          name: "description",
          content: "Manuel Schmidt - Impressum und rechtliche Informationen"
        },
        {
          name: "keywords",
          content:
            "software entwicklung, angular, nodejs, software development, ux, innsbruck, mobile, apps, web, user experience, tests, qualität, platform unabhängig"
        }
      ]
    };
  }
});
