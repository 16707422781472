







































import Vue from "vue";
export default Vue.extend({
  name: "SoftwareDevelopment",
  metaInfo: () => {
    return {
      title: "Manuel Schmidt",
      titleTemplate: "%s - Kunden Referenzen",
      meta: [
        {
          name: "description",
          content: "Software Produkte die durch mich umgesetzt werden."
        },
        {
          name: "keywords",
          content:
            "software entwicklung, angular, nodejs, software development, ux, innsbruck, mobile, apps, web, user experience, tests, qualität, platform unabhängig"
        }
      ]
    };
  }
});
