



























































































































































































































































import Vue from "vue";

interface Test {
  a: string;
}
enum NextOp {
  "AUTO",
  "MANUAL",
  "BREAK"
}

export default Vue.extend({
  name: "DigitalTransformation",
  data: () => {
    return {
      phone: 2,
      desktop: 2,
      nextOp: NextOp.AUTO,
      updateInterval: undefined as number | undefined
    };
  },
  mounted: function() {
    this.autoMoveDataObject();
    this.updateInterval = setInterval(this.autoMoveDataObject.bind(this), 5000);
    window.addEventListener("blur", () => (this.nextOp = NextOp.BREAK));
    window.addEventListener("focus", () => {
      if (this.nextOp === NextOp.BREAK) {
        this.nextOp = NextOp.AUTO;
      }
    });
  },

  beforeDestroy: function() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
      this.updateInterval = undefined;
    }
    window.removeEventListener("blur", this.pauseAnimation);
    window.removeEventListener("focus", this.resumeAnimation);
  },
  methods: {
    pauseAnimation: function() {
      if (this.nextOp === NextOp.AUTO) {
        this.nextOp = NextOp.BREAK;
      }
    },

    resumeAnimation: function() {
      if (this.nextOp === NextOp.BREAK) {
        this.nextOp = NextOp.AUTO;
      }
    },

    clickElement: function(refId: number, elementID: number) {
      const target = (this.$refs[refId] as HTMLElement[])[0];
      this.nextOp = NextOp.MANUAL;
      this.moveDataObject(target, elementID);
    },

    autoMoveDataObject: function() {
      if (this.nextOp === NextOp.AUTO) {
        const moveObjectId = 3;
        const target = (this.$refs["d" + moveObjectId] as HTMLElement[])[0];
        this.moveDataObject(target, moveObjectId);
      }
    },

    moveDataObject: function(target: HTMLElement, elementID: number) {
      if (elementID <= this.desktop) {
        const moveAnimation = target.animate(
          [
            { transform: target.style.transform },
            { transform: "translate(250px, 180px)", easing: "ease-in" },
            { transform: "translate(885px, 180px)" }
          ],
          2000
        );
        moveAnimation.onfinish = () => {
          this.desktop -= 1;
          this.phone += 1;
        };
      } else {
        const moveAnimation = target.animate(
          [
            { transform: target.style.transform },
            { transform: "translate(885px, 180px)", easing: "ease-in" },
            { transform: "translate(250px, 180px) scale(2)" }
          ],
          2000
        );
        moveAnimation.onfinish = () => {
          this.desktop += 1;
          this.phone -= 1;
        };
      }
    }
  }
});
